// axiosInstance.js

import axios from 'axios';
import { getToken } from "@/utils/token";


// Get base URL from environment variable
const baseURL = process.env.VUE_APP_BASE_URL;

// Create axios instance with base URL
const axiosInstance = axios.create({
  baseURL: `${baseURL}/api`,
  timeout: 5000, // Set timeout if required
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      /**
       * Require each request to have a token in the header
       * all requests are JSON encoded
       */
      config.headers["Content-Type"] = "application/json";

      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {

    return response;
  },
  (error) => {
    // Use Element Plus Message for error handling
    // ElMessage.error(error.message || "An error occurred, contact your system administrator");
    return Promise.reject(error);
  }
);



export default axiosInstance;
