import { register, login, logout, getInfo, resetPassword } from '@/api/users'
import { getToken, setToken, removeToken } from '@/utils/token'
import router from '@/router';

const getDefaultState = () => {
  return {
    token: getToken() || sessionStorage.getItem('token') || '', // Retrieve token from session storage
    email: '',
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
    setToken(token); // Store token using setToken() utility function
    sessionStorage.setItem('token', token); // Store token in session storage
  },
  SET_USER_INFO: (state, userInfo) => {
    state.name = userInfo.name;
    state.email = userInfo.email;
    state.role = userInfo.role;
  },
  REMOVE_TOKEN: (state) => {
    state.token = ''; // Remove token from Vuex state
    removeToken(); // Remove token from local storage
    sessionStorage.removeItem('token'); // Remove token from session storage
  }
};

const actions = {
  async login({ commit }, userInfo) {
    const { email, password } = userInfo;
    try {
      const response = await login(email, password);
      const { data } = response;
      commit('SET_TOKEN', data.result.token);
      resolve();
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  },

  async getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then(response => {
          const { data } = response;
          if (!data) {
            reject('Verification failed, please login');
          } else {
            commit('SET_USER_INFO', data.result.user);
            resolve(data);
          }
        })
        .catch(error => {
          console.error('Error in getInfo:', error);
          if (error.response && error.response.status === 401) {
            removeToken();
            commit('REMOVE_TOKEN'); // Handle token expiration: remove token and log the user out
            commit('RESET_STATE'); // Reset the state
            sessionStorage.removeItem('token'); // Remove token from session storage
            router.push({ name: 'login' }); // Redirect to the login page
          } else {
            reject(error);
          }
        });
    });
  },

  async resetUserPassword({ commit }, { userUuid, formData }) {
    try {
      // Use the API function to reset the password
      const response = await resetPassword(userUuid, formData);

    } catch (error) {
      // Handle errors
      console.error('Error resetting user password:', error);
      throw error;
    }
  },

  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(() => {
          commit('REMOVE_TOKEN'); // Remove token and log the user out
          commit('RESET_STATE'); // Reset the state
          removeToken();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //reset token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken()
      commit('RESET_STATE');
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
