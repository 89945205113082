import axiosInstance from '@/utils/axiosInstance';

export async function register(formValues) {
  try {
    const response = await axiosInstance.request({
      url: '/auth/register',
      method: "post",
      data: formValues,
    });

    return response; 
  } catch (error) {
    throw error; 
  }
}

export async function login(email, password) {
    return axiosInstance.request({
        url: '/auth/login',
        method: 'post',
        data: { email: email, password: password },
    })

}

export async function getInfo(token) {
    return await axiosInstance.request({
        url: '/auth/me',
        method: 'get',
        params: { token }
    })
}

export async function resetPassword(userUuid, formData) {
    return axiosInstance.put(`/admin/users/${userUuid}/reset`, formData);
}

export function logout() {
    return axiosInstance.request({
        url: '/auth/logout',
        method: 'post',
    });
}

