<template>
  <nav class="navbar">
    <div class="logo">
      <h4>Stadi</h4>
    </div>
    <div class="navlinks">
      <router-link to="/">Home</router-link>
      <router-link to="/about">About</router-link>
    </div>
    <div class="registration">
      <p><router-link to='/login'>Login</router-link></p>
      <button><router-link to="/register">Bag A Skill</router-link></button>
    </div>
  </nav>
</template>

<script setup>
</script>

<style lang="scss" scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  margin: 20px 30px 50px 50px;

  .logo {
    font-size: 30px;
    font-weight: 800px;
    padding: 0;
  }

  .navlinks {
    margin-top: 50px;
    display: flex;
    gap: 20px;
    font-size: 15px;
    font-weight: 200px;

    a {
      text-decoration: none;
      color: #2c3e50;
    }

    .router-link-exact-active {
      color: #5E7D9C;
    }
  }

  .registration {
    display: flex;
    gap: 40px;
    font-size: 15px;
    margin: 35px 0;
    cursor: pointer;

    a {
      text-decoration: none;
      color: #2c3e50;

    }

    button {
      background-color: #5E7D9C;
      // color: #ffffff;
      border-radius: 30px;
      font-size: 15px;
      border: none;
      height: 35px;
      padding: 5px 30px;
      outline: none;
      // margin-top: 10px;
      cursor: pointer;

      a {
        text-decoration: none;
        color: #fff;
      }

    }
  }
}
</style>